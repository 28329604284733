@charset "UTF-8";
.no-scroll {
  touch-action: none; }

.theme {
  --color-cell-border: hsl(204, 11%, 83%);
  --color-drag-box-border: hsla(209, 97%, 50%, 0.5);
  --color-drag-box-background: hsla(209, 97%, 65%, 0.5);
  --color-handle-wrapper-text: hsla(209, 100%, 80%, 0.5);
  --color-timeline-text: hsl(0, 0%, 48%);
  --color-header-background: hsla(0, 0%, 97%, 1);
  --color-event-text: hsl(207, 65%, 93%);
  --color-event-background: hsl(202, 100%, 20%);
  --color-event-active-background: hsl(202, 100%, 40%);
  --color-event-hover-background: hsl(202, 100%, 30%);
  --color-event-shadow-1: hsla(202, 100%, 16%, 0.5);
  --color-event-shadow-2: rgba(217, 217, 217, 0.5);
  --color-header-shadow-1: hsla(0, 0%, 50%, 0.2);
  --color-header-shadow-2: hsla(0, 0%, 85%, 0.239);
  --color-timeline-background: white;
  --color-grid-background: white; }

.root {
  --z-index-handle-wrapper: 1;
  --z-index-layer-container: 1;
  --z-index-event: 2;
  --z-index-event-is-pending-creation: 1;
  --z-index-event-active: 3;
  --z-index-draggable: 2;
  --z-index-dragging: 3;
  --z-index-day-header-row: 2;
  --z-index-header: 1;
  --z-index-timeline: 2;
  --z-index-timeline-header: 1;
  contain: strict;
  overflow: auto;
  background: var(--color-grid-background, white);
  border: 1px solid var(--color-cell-border); }
  .root,
  .root * {
    box-sizing: border-box; }

.grid-root {
  position: relative;
  display: table;
  overflow: auto;
  width: 100%; }

.debug:not(.debug-active) {
  display: none; }

.calendar {
  user-select: none;
  text-align: center;
  display: flex;
  position: relative;
  border-collapse: collapse; }

.react-draggable {
  position: absolute; }

.handle-wrapper {
  z-index: var(--z-index-handle-wrapper);
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-position: center;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  box-sizing: border-box;
  color: var(--color-handle-wrapper-text);
  vertical-align: middle; }
  .handle-wrapper .handle.top {
    top: -5px !important;
    cursor: n-resize !important; }
  .handle-wrapper .handle.bottom {
    bottom: 8px !important;
    cursor: s-resize !important; }
  .handle-wrapper .handle.top::after,
  .handle-wrapper .handle.bottom::after {
    display: block;
    text-align: center;
    content: '═'; }

.layer-container {
  position: relative;
  height: 100%;
  z-index: var(--z-index-layer-container); }
  .layer-container .calendar {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .layer-container .event {
    z-index: var(--z-index-event); }
  .layer-container .calendar {
    position: absolute;
    z-index: var(--z-index-calendar); }
  .layer-container .drag-box {
    will-change: transform;
    contain: strict;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 0;
    top: 0;
    z-index: var(--z-index-drag-box);
    border: 3px dashed var(--color-drag-box-border);
    background: var(--color-drag-box-background);
    width: 100px;
    height: 100px; }

.draggable {
  z-index: var(--z-index-draggable); }

.button-reset {
  outline: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  all: unset;
  text-align: initial;
  color: inherit; }

.is-draggable {
  user-select: none;
  cursor: grab; }

.is-draggable:global(.react-draggable-dragging) {
  cursor: grabbing;
  z-index: var(--z-index-dragging) !important; }

.event {
  will-change: height;
  contain: content;
  user-select: none;
  display: block;
  z-index: var(--z-index-event);
  margin: 0;
  position: absolute;
  font-size: 1rem;
  background: var(--background, var(--color-event-background));
  color: var(--color-event-text);
  left: 0;
  right: 0;
  width: 100px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis; }
  .event:not(.is-pending-creation):not([disabled]):not(.is-disabled):focus, .event:not(.is-pending-creation):not([disabled]):not(.is-disabled):hover {
    box-shadow: 0 1px 5px 1px var(--color-event-shadow-1), 0 5px 10px 1px var(--color-event-shadow-2); }
  .event:not(.is-pending-creation):not([disabled]):not(.is-disabled):focus-within {
    box-shadow: 0 1px 5px 1px var(--color-event-shadow-1), 0 5px 10px 1px var(--color-event-shadow-2); }
  .event:not(.is-pending-creation):not([disabled]):not(.is-disabled):hover {
    --background: var(--color-event-hover-background); }
  .event:not(.is-pending-creation):not([disabled]):not(.is-disabled):focus {
    --background: var(--color-event-active-background);
    z-index: var(--z-index-event-active); }
  .event:not(.is-pending-creation):not([disabled]):not(.is-disabled):focus-within {
    --background: var(--color-event-active-background);
    z-index: var(--z-index-event-active); }
  .event.is-pending-creation {
    z-index: var(--z-index-event-is-pending-creation);
    opacity: 0.75; }

.hours-container {
  display: flex; }

.day-column {
  flex: 1 1 var(--cell-width, 250px);
  min-width: var(--cell-min-width, 150px); }
  .day-column .day-hours {
    display: flex;
    flex-direction: column;
    position: relative; }
  .day-column .cell {
    flex: 1 1 var(--cell-height, 40px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; }
    .day-column .cell .time {
      color: var(--color-timeline-text);
      font-size: 0.75em;
      text-transform: uppercase; }
  .day-column .title {
    padding: 10px 10px;
    font-size: 20px;
    justify-content: center;
    align-items: center; }

.cell:first-child,
.cell + .cell {
  border: 1px solid var(--color-cell-border);
  border-bottom: 0;
  border-left: 0; }

.cell:not(.is-hour-start) {
  border-top-width: 1px;
  border-top-style: dashed; }

.header .cell {
  border-top-width: 0; }

.day-header-row {
  z-index: var(--z-index-day-header-row); }

.sticky-top {
  position: sticky;
  top: 0; }

.sticky-left {
  position: sticky;
  left: 0; }

.header {
  will-change: transform;
  z-index: var(--z-index-header);
  background: var(--color-header-background);
  box-shadow: 0 1px 5px var(--color-header-shadow-1), 0 1px 10px var(--color-header-shadow-2); }

.day-column.first {
  width: 100px;
  max-width: 100px;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0; }

.popup {
  position: absolute;
  top: 0;
  left: 0; }

.range-boxes .event-content {
  padding: 5px 10px;
  font-weight: bold;
  font-size: 13px;
  width: 100%;
  height: 100%;
  white-space: pre-wrap; }
  .range-boxes .event-content > * {
    width: 100%; }

.range-boxes .start {
  display: inline; }
  .range-boxes .start:after {
    content: ' – ';
    display: inline; }

.range-boxes .end {
  display: inline; }

.timeline {
  display: table-cell;
  text-align: end;
  z-index: var(--z-index-timeline); }
  .timeline .day-column {
    background: var(--color-timeline-background);
    min-width: auto;
    flex: 0 0 auto; }

.timeline .header {
  z-index: var(--z-index-timeline-header);
  box-shadow: none;
  background: var(--color-timeline-background); }
  .timeline .header .cell {
    visibility: hidden; }

.timeline > *:not(.header) .cell {
  position: relative;
  top: -8px;
  border: none;
  border-color: transparent;
  text-align: end;
  justify-content: flex-end; }
  .timeline > *:not(.header) .cell:first-child {
    visibility: hidden; }
  .timeline > *:not(.header) .cell .time {
    background: inherit;
    padding-right: 1rem; }
